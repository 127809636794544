@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  background: #000;
  color: #000;
  overflow: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.spinner-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 40px;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid transparent;
  border-top: 10px solid #fff;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Animations */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 36px;
  }

  .subtitle {
    font-size: 14px;
  }
  
}
